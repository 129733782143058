export const baseCommands = [
    'ls',
    'cd',
    'help',
    'ed',
    'archive-x',
    'tv',
    'git',
    'twit',
    'yt',
    'contact',
    'settings'
];