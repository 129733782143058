import React from 'react';

const HelloPage = () => {
    return (
        <section id="hello-page">
            <h1>H<span className="text-green">e</span>llo Frien<span className="text-green">d</span>...</h1>
        </section>
    );
};

export default HelloPage;