import { auth, firestore } from "./firebase";

export const loginWithEmail = async (email, pass, callback) => {
    try {
        return auth.signInWithEmailAndPassword(email, pass).then()
    } catch (err) {
        alert(err.message);
    }
}

export const getData = async () => {
    try {
        return firestore.collection('nienormalny').doc('nienormalny').get().then(snap => {
            return snap.data();
        })
    }  catch (err) {
        alert(err.message);
    }
}