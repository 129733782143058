export const Apis = {
    GOOGLEAPI: 'AIzaSyDav9EgCWP747o9MYLSQnysftZKsBSjPyg' // 'AIzaSyBJn2oyXq4sr3I_uvWwsczn8TsQaRHNEGI'
}
export const RegExps = {
    html: /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/,
    html2: /(<script(\s|\S)*?<\/script>)|(<style(\s|\S)*?<\/style>)|(<!--(\s|\S)*?-->)|(<\/?(\s|\S)*?>)/,
    css: /(?:\s*\S+\s*{[^}]*})+/
}
export const YoutubeArchive = {
    0: {
        title: '2D Intro for GraczWatch - RETRO STYLE!',
        url: 'https://www.youtube.com/watch?v=zHsn78_Axs8',
        id: 'zHsn78_Axs8'
    },
    1: {
        title: '2D Transparent Intro for TheGroOfile',
        url: 'https://www.youtube.com/watch?v=3OHVl2WAQFA',
        id: '3OHVl2WAQFA'
    },
    2: {
        title: 'Free 2D intro for Darkon',
        url: 'https://www.youtube.com/watch?v=9FnElbM7ki4',
        id: '9FnElbM7ki4'
    },
    3: {
        title: 'Free Intro 2d for OlekShoty',
        url: 'https://www.youtube.com/watch?v=_Vzm6oQ_ooI',
        id: '_Vzm6oQ_ooI'
    },
    4: {
        title: 'Video Montage for Jacob Parkour | 60fps',
        url: 'https://www.youtube.com/watch?v=SSwwRBDHR-4',
        id: 'SSwwRBDHR-4'
    },
    5: {
        title: 'Speed drawing - crazy shit #1',
        url: 'https://www.youtube.com/watch?v=5N3dLpTY18A',
        id: '5N3dLpTY18A'
    },
    6: {
        title: 'Fast drawing on Galaxy Note 8 - Love to the death',
        url: 'https://www.youtube.com/watch?v=2d4dxXruZzU',
        id: '2d4dxXruZzU'
    },
    7: {
        title: '3D Intro for Staff (60FPS) #illuminati',
        url: 'https://www.youtube.com/watch?v=i2P-HVa96g0',
        id: 'i2P-HVa96g0'
    },
    8: {
        title: 'SPEEDART| Banner for Youtube - Mona',
        url: 'https://www.youtube.com/watch?v=00lkT9BtNjE',
        id: '00lkT9BtNjE'
    },
    9: {
        title: 'SPEEDART | Banner Youtube for Jacob Parkour',
        url: 'https://www.youtube.com/watch?v=_qQHwHriFro',
        id: '_qQHwHriFro'
    },
    10: {
        title: '3D Intro - KJZD (60FPS) | Do you wont your own? Check Description!',
        url: 'https://www.youtube.com/watch?v=Hd6SOsIstRw',
        id: 'Hd6SOsIstRw'
    },
    11: {
        title: 'Speed Art - Making Drawing Intro for Homes',
        url: 'https://www.youtube.com/watch?v=SXbP_Ud_xjk',
        id: 'SXbP_Ud_xjk'
    },
    12: {
        title: 'Magic of Turinland - PrePreAlpha - My animations in game',
        url: 'https://www.youtube.com/watch?v=HHeVETTiWh8',
        id: 'HHeVETTiWh8'
    },
    13: {
        title: 'Animations and Graphics for WACIKS STREAMER',
        url: 'https://www.youtube.com/watch?v=Xaf6l094bu0',
        id: 'Xaf6l094bu0'
    },
    14: {
        title: 'Thematic Intro 3D for AKTYWNY (60FPS) - low poly style',
        url: 'https://www.youtube.com/watch?v=4CenKtwKIPY',
        id: '4CenKtwKIPY'
    },
    15: {
        title: 'Intro + Lower Thirds for MKRR3 (60FPS)',
        url: 'https://www.youtube.com/watch?v=eSSPHIKWmcA',
        id: 'eSSPHIKWmcA'
    },
    16: {
        title: 'Intro 2D for X-HACK (60 FPS) Something new :)',
        url: 'https://www.youtube.com/watch?v=LKkIfUxPj5A',
        id: 'LKkIfUxPj5A'
    },
    17: {
        title: 'Tworzenie animacji - powrót do życia #1 "Wieża łuczników"',
        url: 'https://www.youtube.com/watch?v=y_07sLT8EXM',
        id: 'y_07sLT8EXM'
    },
    18: {
        title: 'Fire Animation in PhotoShop - Inspiration Clip',
        url: 'https://www.youtube.com/watch?v=G3Hbt1bQ-PM',
        id: 'G3Hbt1bQ-PM'
    },
    19: {
        title: 'Intro 2D for XHack (60FPS) - Retro Style / Arcade Games',
        url: 'https://www.youtube.com/watch?v=K32V_DBDNj8',
        id: 'K32V_DBDNj8'
    },
    20: {
        title: 'Intro for Broda ty - Borderlands STYLE',
        url: 'https://www.youtube.com/watch?v=wYF0zevuPC4',
        id: 'wYF0zevuPC4'
    },
    21: {
        title: 'Inspiration Clip #12 - 3D Opening',
        url: 'https://www.youtube.com/watch?v=BfTOCg6r95Y',
        id: 'BfTOCg6r95Y'
    },
    22: {
        title: 'Free 2D Intro | Tamplate for AAE',
        url: 'https://www.youtube.com/watch?v=o-0cCPNRvlU',
        id: 'o-0cCPNRvlU'
    }
}
export const PhotoArchive = {
    0: {
        title: 'Winter Card for Eurodata ProPep',
        url: '/assets/photo-archive/propep-winter-card.png',
        type: 'landscape'
    },
    1: {
        title: 'Our path - painted on Samsung Galaxy Note 8',
        url: '/assets/photo-archive/our_path.jpg',
        type: 'portrait'
    },
    2: {
        title: 'Letter face - created with Photoshop CC',
        url: '/assets/photo-archive/letter_face.png',
        type: 'landscape'
    },
    3: {
        title: 'Our World - created with Photoshop CC',
        url: '/assets/photo-archive/our_world.png',
        type: 'landscape'
    },
    4: {
        title: 'Lets play - painted on Samsung Galaxy Note 8',
        url: '/assets/photo-archive/lets_play.png',
        type: 'portrait'
    },
    5: {
        title: 'Brain Washington - painted on Samsung Galaxy Note 8',
        url: '/assets/photo-archive/brain_washington.png',
        type: 'portrait'
    },
    6: {
        title: 'My first avatar - painted on Samsung Galaxy Note 8',
        url: '/assets/photo-archive/my_first_avatar.png',
        type: 'portrait'
    }
}
export const PrivateProjects = {
    0: {
        name: 'Luxus-landhaus',
        description: 'Landing page for luxury holiday-house.',
        url: 'https://luxus-landhaus.com/',
        image: '/assets/webs/luxus-landhause-web.png',
        tech: ['html', 'css3', 'less', 'js', 'jquery'],
        active: false,
    },
    1: {
        name: 'Lohn Personal Agentur',
        description: 'Landing page for digitale accountings - company. (My first landing page)',
        url: 'https://lpa-service.de/index.php',
        image: '/assets/webs/lpa-services-web.png',
        tech: ['html', 'css3', 'js', 'jquery'],
        active: false,
    },
    2: {
        name: 'Prifil Designer',
        description: 'Landing page for designer.',
        url: 'https://prifil.com',
        image: '/assets/webs/prifil-web.png',
        tech: ['html', 'css3', 'js', 'jquery'],
        active: false,
    },
    3: {
        name: 'Trippy Pixels - OLD',
        description: 'Small CMS for Trippy Pixels Team. Simple landing page and backend for organize work',
        url: 'http://trippy-pixels.com/',
        image: '/assets/webs/trippy-pixels-web.png',
        tech: ['php', 'html', 'css3', 'js', 'jquery'],
        active: true,
    },
    4: {
        name: 'BSC-CC Website',
        description: 'Landing page with Blog for selling trainings, coaching and consultation - company.',
        url: 'https://bsc-cc.de/',
        image: '/assets/webs/bsc-cc-web.png',
        tech: ['wordpress', 'html', 'css3', 'js', 'jquery'],
        active: true,
    },
    5: {
        name: 'My Portfolio Website',
        description: 'Landing page with some extra hidden functions.',
        url: 'https://nienormalny.org/',
        image: '/assets/webs/my-web.png',
        tech: ['react', 'html', 'less', 'js (ES6)', 'lodash', 'momentjs'],
        active: true,
    },
    6: {
        name: 'Lohnmühle',
        description: 'Landing page for Lohnmühle company',
        url: 'https://lohnmuehle.de/',
        image: '/assets/webs/lohnmuele.png',
        tech: ['react', 'html', 'scss', 'js (ES6)', 'lodash'],
        active: true,
    },
    7: {
        name: 'Business App for BSC-CC',
        description: 'Custome CMS for BSC-CC portal.',
        url: 'https://portal.bsc-cc.de/',
        image: '/assets/webs/bsc_ground_profile.png',
        tech: ['firestore', 'react', 'html', 'scss', 'js (ES6)', 'lodash', 'momentjs', 'material-ui'],
        active: true,
    },
    8: {
        name: 'Otravida Stuttgart',
        description: 'Custome CMS for Otravida Tattoo Studio in Stuttgart',
        url: 'https://otravida-tattoo.de/',
        image: '/assets/webs/otravida_tattoo.png',
        tech: ['firebase functions', 'firestore', 'animejs', 'react', 'html', 'scss', 'js (ES6)', 'lodash', 'momentjs'],
        active: true,
    },
    9: {
        name: 'Passion & Wine',
        description: 'Passion and Wine website.',
        url: 'https://passion.wine/',
        image: '/assets/webs/passion_wine.png',
        tech: ['wordpres', 'html', 'css3', 'js'],
        active: true,
    },
    10: {
        name: 'Trippy Pixels - NEW (Work in progress)',
        description: 'Custome CMS for Trippy Pixels portal.',
        url: 'https://nienormalny.org/websites/trippy-pixels',
        image: '/assets/webs/trippy_pixels.png',
        tech: ['firebase functions', 'firestore', 'animejs', 'react', 'html', 'scss', 'js (ES6)', 'lodash', 'momentjs'],
        active: true,
    }
}