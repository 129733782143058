import React, { useState, useEffect, useCallback } from 'react';
import * as _ from 'lodash';
import './styles/style.scss';
import Projects from './components/projects.component';
import HelloPage from './components/hellopage.component';
import Navigation from './components/navigation.component';
import classnames from 'classnames';
import Videos from './components/videos.component';
import AboutMe from './components/aboutme.component';
import MiniEditor from './components/minieditor.component';
import Archive from './components/archive.component';
import StreamTv from './components/stream.component';
import {GitHub, YouTube, Twitter} from '@material-ui/icons';
import { db } from './utils/firebase';
import moment from 'moment';
import Login from './components/login.component';
import Settings from './components/settings.component';

function App() {
  const [loggedIn, setLoggedIn] = useState(null);
  const [pr, setPr] = useState(false);
  const [hp, setHp] = useState(true);
  const [vp, setVp] = useState(false);
  const [ap, setAp] = useState(false);
  const [me, setMe] = useState(false);
  const [arp, setArp] = useState(false);
  const [tv, setTv] = useState(false);
  const [lg, setLg] = useState(false);
  const [sg, setSg] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [reloadNav, setReloadNav] = useState(true);
  const [devView, setDevView] = useState(false);
  const [userView, setUserView] = useState(true);
  const [winners, setWinners] = useState(null);
  const [winner, setWinner] = useState(undefined);
  const [opened, setOpened] = useState(false);
  const [finish, setFinish] = useState(false);
  let hiddenSen = '';
  let hiddenNum = '';
  const hiddenSentense = {
    'y': false,
    'o': false,
    'u': false,
    'a': false,
    'r': false,
    'e': false,
    'g': false,
    'l': false,
    'i': false,
    's': false,
    '!': false
  };
  const hiddenNumbers = {
    5: false,
    3: false,
    1: false,
    7: false,
    6: false,
    4: false,
    8: false,
    2: false
  };
  const [seeSentense, setSeeSentense] = useState(false);
  const [exit, setExit] = useState(false);
  const [eggs, setEggs] = useState({
    'ed': {
      title: 'Mini Editor',
      checked: false
    },
    'archive-x': {
      title: 'Archive X',
      checked: false
    },
    'tv': {
      title: 'My live streams',
      checked: false
    },
    'hiddenSentense': {
      checked: false
    }
  });

  useEffect(() => {
    if (!reloadNav) {
      setReloadNav(true);
    }
  }, [reloadNav]);

  useEffect(() => {
    db.ref('konkurs/egg-finder').once('value', snap => setWinners(snap.val()));
    console.log('_𝙷 𝗲 𝚕 𝚕 𝚘___𝙵 𝚛 𝚒 𝚎 𝚗 𝗱_');
    console.log(loggedIn);
  }, []);

  const handleSave = useCallback(async event => {
    event.preventDefault();
    const {name, email} = event.target.elements;
    try {
      if (winners && Object.keys(winners).length >= 3) {
        db.ref('konkurs/egg-finder').push({
          'name': name.value,
          'email': email.value,
          'createdAt': moment().format('DD-MM-YYYY HH:mm:ss')
        });
        setWinner(false);
        db.ref('konkurs/egg-finder').once('value', snap => setWinners(snap.val()));
      } else {
        db.ref('konkurs/egg-finder').push({
          'name': name.value,
          'email': email.value,
          'createdAt': moment().format('DD-MM-YYYY HH:mm:ss')
        });
        setWinner(true);
        db.ref('konkurs/egg-finder').once('value', snap => setWinners(snap.val()));
      }
    } catch(err) {
      console.log('Err', err);
    }
  }, [winners]);

  const toggleView = (event, view) => {
    switch (view) {
      case 'dev':
        setUserView(false);
        return setDevView(true);
      case 'user':
        setDevView(false);
        return setUserView(true);
      default:
        setUserView(true);
        return setDevView(false);
    }
  }

  return (
    <div className="App">
      {userView && !hp ? <HelloPage /> : hp && <HelloPage onSelect={page => {
        if (page === 'Projects') {
          setPr(!pr);
        }
        setHp(false);
      }}/>}
      {/* {<Settings />} */}
      {userView && !ap ? <AboutMe /> : ap && <AboutMe/>}
      {userView && !pr ? <Projects /> : pr && <Projects />}
      {reloadNav && !userView && devView && <Navigation loggedIn={loggedIn} onSelect={page => {
        if (page === 'Projects') {
          setHp(false);
          setVp(false);
          setAp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setPr(true);
          setLg(false);
          setSg(false);
          setReloadNav(!reloadNav);
        } else if (page === 'Home') {
          setPr(false);
          setVp(false);
          setAp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setHp(true);
          setLg(false);
          setSg(false);
          setReloadNav(!reloadNav);
        }
        else if (page === 'Videos') {
          setPr(false);
          setHp(false);
          setAp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setVp(true);
          setLg(false);
          setSg(false);
          setReloadNav(!reloadNav);
        } else if (page === 'About me') {
          setPr(false)
          setHp(false);
          setVp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setAp(true);
          setLg(false);
          setSg(false);
          setReloadNav(!reloadNav);
        } else if (page === 'Login') {
          setPr(false)
          setHp(false);
          setVp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setAp(false);
          setLg(true);
          setSg(false);
          setReloadNav(!reloadNav);
        } else if (page === 'settings') {
          setPr(false)
          setHp(false);
          setVp(false);
          setMe(false);
          setArp(false);
          setTv(false);
          setAp(false);
          setLg(false);
          setSg(true);
          setReloadNav(!reloadNav);
        } else if (page === 'ed') {
          setPr(false)
          setHp(false);
          setVp(false);
          setAp(false);
          setArp(false);
          setTv(false);
          setLg(false);
          setMe(true);
          setSg(false);
          setReloadNav(!reloadNav);
          setEggs({...eggs, 'ed': {...eggs['ed'], checked: true}});
        } else if (page === 'archive-x') {
          setPr(false)
          setHp(false);
          setVp(false);
          setAp(false);
          setMe(false);
          setTv(false);
          setArp(true);
          setSg(false);
          setReloadNav(!reloadNav);
          setEggs({...eggs, 'archive-x': {...eggs['archive-x'], checked: true}});
        } else if (page === 'tv') {
          setPr(false)
          setHp(false);
          setVp(false);
          setAp(false);
          setMe(false);
          setArp(false);
          setLg(false);
          setTv(true);
          setSg(false);
          setReloadNav(!reloadNav);
          setEggs({...eggs, 'tv': {...eggs['tv'], checked: true}});
        } else if (page === 'git') {
          setReloadNav(!reloadNav);
        } else if (page === 'twit') {
          setReloadNav(!reloadNav);
        } else if (page === 'yt') {
          setReloadNav(!reloadNav);
        }
      }}/>}
      {!userView && devView && <div className={classnames('help', {open: toggleHelp})} onClick={() => setToggleHelp(!toggleHelp)}>
        <div className="dotted-border">
            <span className="top"></span>
            <span className="right"></span>
            <span className="bottom"></span>
            <span className="left"></span>
        </div>
        {toggleHelp && <div>
          <p>Commands:</p>
          <ul>
            <li>ls - to see available pages</li>
            <li>cd - for selecting pages</li>
            <li>contact - to print my contact email</li>
            <li>git - to see my github profile</li>
            <li>twit - to see my twitter profile</li>
            <li>yt - to see my youtube channel</li>
            <li>help - to see same info as here in webterminal</li>
          </ul>
          <p>To go in another page type <span className="text-red">"cd "</span> and available page from <span className="text-red">"ls"</span>.</p>
          <p>Example: <span className="text-red">cd Projects</span></p>
        </div>}
      </div>}
      {userView && !vp ? <Videos /> : vp && <Videos/>}
      {userView && <div id="contact">
        <h1>Contact</h1>
        <h3><a href="mailto:dawid.szadurski@gmail.com" className="text-orange">dawid.szadurski@gmail.com</a></h3>
      </div>}
      {me && <MiniEditor/>}
      {arp && <Archive/>}
      {devView && lg && <Login onLogin={val => {
        console.log(val);
        setLoggedIn(true);
      }}/>}
      {tv && <StreamTv title={eggs['tv'].title}/>}
      {/*!exit && eggs['tv'].checked && eggs['ed'].checked && eggs['archive-x'].checked && */}
      {!exit && opened && <div id="congratulation">
        {document.addEventListener('keyup', key => {
          _.map(hiddenSentense, (letter, k) => {
            if (k === key.key) {
              hiddenSen += key.key;
              if (new RegExp(/youareglorious!/, 'g').test(hiddenSen)) {
                setSeeSentense(true);
                setEggs({...eggs, 'hiddenSentense': {...eggs['hiddenSentense'], checked: true}});
                setFinish(true);
              }
            }
          });
          _.map(hiddenNumbers, (number, k) => {
            if (k === key.key) {
              hiddenNum += key.key;
              if (new RegExp(/53117631241832/, 'g').test(hiddenNum)) {
                setExit(true);
              }
            }
          });
        })}
        {!seeSentense ? <h1>N<span className="text-green">I</span><sup>5</sup>C<span className="text-blue">E</span><sup>3</sup><span className="text-orange">!</span><sup>1</sup> <span className="text-red">Y</span><sup>1</sup>O<span className="text-green">U</span><sup>7</sup> F<span className="text-green">O</span><sup>6</sup><span className="text-red">U</span><sup>3</sup>ND <span className="text-blue">A</span><sup>1</sup><span className="text-green">L</span><sup>2</sup>MOST ALL EASTE<span className="text-green">R</span><sup>4</sup> E<span className="text-green">G</span><sup>1</sup>GS! THAT WA<span className="text-green">S</span><sup>8</sup> N<span className="text-green">O</span><sup>3</sup>T S<span className="text-red">O</span><sup>2</sup> HA<span className="text-blue">R</span><sup>2</sup>D!</h1> 
        : _.isUndefined(winner) ? (<div className="hidden-sentense">
          <h1><span className="text-red">You</span> <span className="text-blue">are</span> <span className="text-green">glorious</span><span className="text-orange">!</span><sup className="final">WRITE TO EXIT: 53117631241832</sup></h1>
          <form onSubmit={handleSave}>
            <div className="dotted-border">
              <span className="top"></span>
              <span className="right"></span>
              <span className="bottom"></span>
              <span className="left"></span>
            </div>
            <label>
              <b>Your twitch nickname:</b>
              <input className="standard-input" name="name" type="text" placeholder="Twitch nickname"/>
            </label>
            <label>
              <b>Your udemy account email:</b>
              <input className="standard-input" name="email" type="email" placeholder="Udemy account email"/>
            </label>
            <button className="standard-btn blue-btn" type="submit">
              <div className="dotted-border">
                <span className="top"></span>
                <span className="right"></span>
                <span className="bottom"></span>
                <span className="left"></span>
              </div>
              Check if you are one of three winners!
            </button>
          </form>
        </div>)
        : winner ? <h1 className="text-green">Congratulations! You are one of three egg finders! Wait until i contact you throw your email! <sup className="final">WRITE TO EXIT: 53117631241832</sup></h1> : <h1 className="text-red">Sorry, to late! :-( Thank you for taking part in my little competition! <sup className="final">WRITE TO EXIT: 53117631241832</sup></h1>}
      </div>}
      {!exit && !userView && devView && !finish && <div id="egg-counter">
        <p>Find eggs</p>
        <div className="eggs">
          {_.map(eggs, (egg, key) => {
            return (
              <div className="egg" key={key}>
                <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
                </div>
                <span className={classnames('checkmark', {checked: egg.checked})}>&#10004;</span>
              </div>
            )
          })}
        </div>
        {!exit && eggs['tv'].checked && eggs['ed'].checked && eggs['archive-x'].checked && <button type="button" className="standard-btn" style={{marginTop: 10}} onClick={() => setOpened(true)}>
          <div className="dotted-border">
            <span className="top"></span>
            <span className="right"></span>
            <span className="bottom"></span>
            <span className="left"></span>
          </div>
          Open
        </button>}
      </div>}
      <div className="socials">
          <div type="button" className={classnames("standard-btn btn")} onClick={(e) => window.open('https://github.com/Nienormalny', '_blank')}>
              <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
              </div>
              <GitHub/>
          </div>
          <div type="button" className={classnames("standard-btn btn red-btn")} onClick={(e) => window.open('https://www.youtube.com/channel/UCMViGP-LyHRtsr5uGcnig1w', '_blank')}>
              <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
              </div>
              <YouTube/>
          </div>
          <div type="button" className={classnames("standard-btn btn blue-btn")} onClick={(e) => window.open('https://twitter.com/NienormalnyFX', '_blank')}>
              <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
              </div>
              <Twitter/>
          </div>
      </div>
      <div id="view-toggle">
        <div className="btns-nav">
          <div type="button" className={classnames("standard-btn blue-btn", {active: userView})} onClick={(e) => toggleView(e, 'user')}>
              <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
              </div>
              <span>User view</span>
          </div>
          <div type="button" className={classnames("standard-btn blue-btn", {active: devView})} onClick={(e) => toggleView(e, 'dev')}>
              <div className="dotted-border">
                  <span className="top"></span>
                  <span className="right"></span>
                  <span className="bottom"></span>
                  <span className="left"></span>
              </div>
              <span>Dev view</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
