import React from 'react';
import { loginWithEmail } from '../utils/database';

const Login = ({onLogin}) => {
    const handleLogin = e => {
        e.preventDefault();
        const {email, pass} = e.target.elements
        if (email.value && pass.value) {
            loginWithEmail(email.value, pass.value).then(res => onLogin(res))
        }
    }
    return <section id="login">
        <form onSubmit={handleLogin}>
            <div className="dotted-border">
              <span className="top"></span>
              <span className="right"></span>
              <span className="bottom"></span>
              <span className="left"></span>
            </div>
            <label>
                <p>E-mail</p>
                <input name="email" className="standard-input" type="email"/>
            </label>
            <label>
                <p>Password</p>
                <input name="pass" className="standard-input" type="password"/>
            </label>
            <button className="standard-btn green-btn active">
                <div className="dotted-border">
                    <span className="top"></span>
                    <span className="right"></span>
                    <span className="bottom"></span>
                    <span className="left"></span>
                </div>
                Login
            </button>
        </form>
    </section>
}

export default Login;