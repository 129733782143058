import React, { useEffect, useState } from 'react';
import axios from 'axios';

const StreamTv = ({title}) => {
    const [coding, setCoding] = useState({});
    const [gaming, setGamming] = useState({});
    useEffect(() => {
        axios.defaults.headers.common["Client-ID"] = 'h5i3w8qj52nqgtlafi8wg9zy6aitz4';
        axios.defaults.headers.common["Accept"] = 'application/vnd.twitchtv.v5+json';
        axios.get('https://api.twitch.tv/kraken/channels/497196820').then(res => {
            setGamming(res.data);
        });
        axios.get('https://api.twitch.tv/kraken/channels/58560319').then(res => {
            setCoding(res.data);
        });
        //58560319
    }, []);
    return (
        <section id="streams">
            <h1>{title}</h1>
            <div className="flex-box">
                <div className="coding">
                    <h1>Coding stream</h1>
                    <img className="stream-logo" src={coding.logo} alt={coding.name}/>
                    <h3>{coding.name}</h3>
                    <div className="dotted-border">
                        <span className="top"></span>
                        <span className="right"></span>
                        <span className="bottom"></span>
                        <span className="left"></span>
                    </div>
                    <div className="flex-box">
                        <div className="stats">
                            <div className="dotted-border">
                                <span className="right"></span>
                            </div>
                            <p>Stream title: {coding.status}</p>
                            <p>Stream language: {coding.language}</p>
                            <p>Game: {coding.game}</p>
                            <a target="blank" href={coding.url}>Twich link</a>
                            <p>Views: {coding.views}</p>
                            <p>Followers: {coding.followers}</p>
                            <p>+18: {coding.mature ? 'Yes' : 'No'}</p>
                        </div>
                        <div className="live">
                            <div className="status">
                                <div className="dotted-border">
                                    <span className="bottom"></span>
                                </div>
                                {coding.status}
                            </div>
                            <iframe src="https://player.twitch.tv/?channel=nienormalny_&parent=nienormalny.org" frameBorder="0" allowFullScreen={true} scrolling="no" title={coding.status}></iframe>
                        </div>
                    </div>
                </div>
                <div className="gaming">
                    <h1>Gaming stream</h1>
                    <img className="stream-logo" src={gaming.logo} alt={gaming.name}/>
                    <h3>{gaming.name}</h3>
                    <div className="dotted-border">
                        <span className="top"></span>
                        <span className="right"></span>
                        <span className="bottom"></span>
                        <span className="left"></span>
                    </div>
                    <div className="flex-box">
                        <div className="stats">
                            <div className="dotted-border">
                                <span className="right"></span>
                            </div>
                            <p>Stream language: {gaming.language}</p>
                            <p>Game: {gaming.game}</p>
                            <a target="blank" href={gaming.url}>Twich link</a>
                            <p>Views: {gaming.views}</p>
                            <p>Followers: {gaming.followers}</p>
                            <p>+18: {gaming.mature ? 'Yes' : 'No'}</p>
                        </div>
                        <div className="live">
                            <div className="status">
                                <div className="dotted-border">
                                    <span className="bottom"></span>
                                </div>
                                {gaming.status}
                            </div>
                            <iframe src="https://player.twitch.tv/?channel=nienormalny_gaming&parent=nienormalny.org" frameBorder="0" allowFullScreen={true} scrolling="no" title={gaming.status}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StreamTv;